export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Início',
      roles: ['ADMIN', 'GROUP_ADMIN', 'PEDAGOGUE'],
    },
    {
      title: 'Visão Geral',
      key: 'overview',
      icon: 'fe fe-home',
      roles: ['ADMIN', 'GROUP_ADMIN', 'PEDAGOGUE'],
      url: '/overview/',
    },
    {
      category: true,
      title: 'Acadêmico',
      roles: ['ADMIN', 'GROUP_ADMIN', 'EXAMS_ONLY_GROUP_ADMIN', 'PEDAGOGUE'],
    },
    {
      title: 'Turmas',
      key: 'classes',
      icon: 'fa fa-th',
      roles: ['ADMIN', 'GROUP_ADMIN', 'EXAMS_ONLY_GROUP_ADMIN', 'PEDAGOGUE'],
      url: '/classes/',
    },
    {
      title: 'Questões',
      key: 'questions',
      icon: 'fa fa-question-circle',
      roles: ['ADMIN', 'GROUP_ADMIN', 'PEDAGOGUE'],
      url: '/questions/',
    },
    {
      title: 'Professores',
      key: 'teachers',
      icon: 'fa-solid fa-chalkboard-user',
      roles: ['ADMIN', 'GROUP_ADMIN', 'PEDAGOGUE'],
      url: '/teachers/',
    },
    {
      category: true,
      title: 'Treinamentos',
      roles: ['EXAMS_ONLY_GROUP_ADMIN'],
    },
    {
      title: 'Simulados',
      key: 'examsList',
      icon: 'fa fa-clipboard-question',
      roles: ['EXAMS_ONLY_GROUP_ADMIN'],
      url: '/exams/',
    },
    {
      title: 'Questões',
      key: 'questionsList',
      icon: 'fa fa-question-circle',
      roles: ['EXAMS_ONLY_GROUP_ADMIN'],
      url: '/exams/questions/',
    },
    {
      title: 'Estatísticas',
      key: 'examsStatistics',
      icon: 'fa fa-pie-chart',
      roles: ['EXAMS_ONLY_GROUP_ADMIN'],
      url: '/exams/statistics/',
    },
    {
      title: 'Treinamentos',
      key: 'exams',
      icon: 'fa fa-clipboard-question',
      url: '/exams/',
      roles: ['ADMIN', 'GROUP_ADMIN'],
      children: [
        {
          title: 'Simulados',
          key: 'examsList',
          url: '/exams/',
        },
        {
          title: 'Questões',
          key: 'questionsList',
          url: '/exams/questions/',
        },
        {
          title: 'Estatísticas',
          key: 'examsStatistics',
          url: '/exams/statistics/',
        },
      ],
    },
    {
      title: 'Monitorias',
      key: 'bookings',
      icon: 'fa fa-graduation-cap',
      url: '/bookings/',
      roles: ['ADMIN', 'GROUP_ADMIN'],
      children: [
        {
          title: 'Salas',
          key: 'bookingsPage',
          url: '/bookings/',
        },
        {
          title: 'Horários',
          key: 'bookingsAvailabilities',
          url: '/bookings/availabilities/',
        },
        {
          title: 'Bloqueios de horário',
          key: 'availabilityBlocks',
          url: '/bookings/blocks/',
        },
        {
          title: 'Estatísticas',
          key: 'bookingsStatistics',
          url: '/bookings/statistics/',
        },
        {
          title: 'Configurações',
          key: 'bookingsConfigs',
          url: '/bookings/configs/',
        },
      ],
    },
    {
      title: 'Estatísticas',
      key: 'statistics',
      icon: 'fa fa-pie-chart',
      url: '/statistics/',
      roles: ['ADMIN', 'GROUP_ADMIN', 'PEDAGOGUE'],
    },

    {
      category: true,
      title: 'Comunicação',
      roles: ['ADMIN', 'GROUP_ADMIN', 'EXAMS_ONLY_GROUP_ADMIN'],
    },
    {
      title: 'Comunicados',
      key: 'notifications',
      icon: 'fa fa-bell',
      url: '/notifications/',
      roles: ['ADMIN', 'GROUP_ADMIN', 'EXAMS_ONLY_GROUP_ADMIN'],
      children: [
        {
          title: 'Visualizar',
          key: 'notificationsList',
          url: '/notifications/',
        },
        {
          title: 'Criar',
          key: 'createNotifications',
          url: '/notifications/create/',
        },
      ],
    },
    {
      category: true,
      title: 'Administração',
      roles: ['ADMIN', 'GROUP_ADMIN', 'EXAMS_ONLY_GROUP_ADMIN', 'PEDAGOGUE'],
    },
    {
      title: 'Usuários',
      key: 'users',
      roles: ['ADMIN', 'GROUP_ADMIN', 'EXAMS_ONLY_GROUP_ADMIN', 'PEDAGOGUE'],
      icon: 'fa fa-users',
      url: '/admin/users/',
      children: [
        {
          title: 'Visualizar',
          key: 'usersList',
          url: '/admin/users/',
        },
        {
          title: 'Adicionar',
          key: 'createUsers',
          url: '/admin/users/create/',
        },
        {
          title: 'Importar',
          key: 'importUsers',
          url: '/admin/users/import/',
        },
      ],
    },
    {
      title: 'Finanças',
      key: 'finance',
      roles: ['PEDAGOGUE'],
      icon: 'fa-solid fa-coins',
      url: '/finance/users/',
      children: [
        {
          title: 'Usuários remunerados',
          key: 'financeUsers',
          url: '/finance/users/',
        },
        {
          title: 'Questões remuneradas',
          key: 'financeQuestions',
          url: '/finance/questions/',
        },
        {
          title: 'Relatório',
          key: 'earningsReport',
          url: '/finance/earnings-report/',
        },
      ],
    },
    {
      title: 'Finanças',
      key: 'admin_finance',
      roles: ['ADMIN'],
      icon: 'fa-solid fa-coins',
      url: '/finance/users/',
      children: [
        {
          title: 'Usuários remunerados',
          key: 'financeUsers',
          url: '/finance/users/',
        },
        {
          title: 'Questões remuneradas',
          key: 'financeQuestions',
          url: '/finance/questions/',
        },
        {
          title: 'Relatório de ganhos',
          key: 'earningsReport',
          url: '/finance/earnings-report/',
        },
      ],
    },
    {
      title: 'Configurações',
      key: 'configs',
      icon: 'fa fa-cog',
      url: '/configs/',
      roles: ['ADMIN', 'GROUP_ADMIN'],
      children: [
        {
          title: 'Disciplinas',
          key: 'configDisciplines',
          url: '/configs/disciplines',
        },
      ],
    },
  ]
}
